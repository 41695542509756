import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";
import { GET_CATEGORIES_BY_ADMIN } from "../../pages/admin/AdminDashboard";
import { useForm } from "../../util/hooks";
import { GET_CATEGORIES } from "../student/DashboardCategories";

function CreateCategory({ admin, props }) {
  const [errors, setErrors] = useState({});
  var name = "";

  const { values, onChange, onSubmit } = useForm(createNewCategoryCallback, {
    name: name || "",
  });

  const [createNewCategory] = useMutation(CREATE_NEW_CATEGORY, {
    refetchQueries: [
      {
        query: GET_CATEGORIES,
      },
      {
        query: GET_CATEGORIES_BY_ADMIN,
        variables: { adminId: admin && admin.id },
      },
    ],
    update() {
      setErrors({});
      values.name = "";
    },
    onError(err) {
      // console.log(values);
      // console.log(err);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values,
    client: adminClient,
  });

  function createNewCategoryCallback() {
    createNewCategory();
  }

  // console.log("outside");
  // console.log(values);

  return module ? (
    <form
      className="w-full overflow-hidden flex flex-col "
      onSubmit={onSubmit}
      noValidate
    >
      <h6 className="text-xl text-red-800">Create a Category</h6>
      <p className="text-sm font-normal lg:font-light ">
        Create a new question by entering a name.
      </p>

      <div className="flex flex-col mt-2">
        <table className="table-fixed w-full">
          <thead>
            <tr>
              <th className="w-1/3 md:px-6 px-2 py-1 border-b border-gray-200"></th>
              <th className="md:px-6 px-2 py-1 border-b border-gray-200"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-sm py-2 border-b border-gray-200">
                <label className=" font-semibold uppercase tracking-wide ">
                  Name
                </label>
              </td>
              <td className="text-sm py-2 border-b border-gray-200">
                <input
                  className={`shadow appearance-none border rounded w-full py-1 px-2 font-normal lg:font-light focus:outline-none    ${
                    errors.name ? "border-red-800" : ""
                  }`}
                  name="name"
                  placeholder=""
                  value={values.name}
                  onChange={onChange}
                  error={errors.name ? "true" : "false"}
                  type="text"
                />
                {errors.name && (
                  <p className="text-red-800 font-normal lg:font-light">
                    <b>&#33;</b> {errors.name}
                  </p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="text-right md:text-sm mx-auto mt-4 flex focus:outline-none">
          <button
            type="submit"
            className="flex focus:outline-none border-2 mx-auto border-red-800 px-4 py-2 uppercase text-red-800 rounded-lg  hover:shadow-md hover:bg-red-800 hover:text-white tracking-wide text-sm font-semibold "
          >
            Save
          </button>
        </div>
      </div>
    </form>
  ) : (
    <></>
  );
}

const CREATE_NEW_CATEGORY = gql`
  mutation createNewCategory($name: String!) {
    createNewCategory(name: $name) {
      id
      name
      adminId
      createdAt
    }
  }
`;

export default CreateCategory;
