import { gql, useQuery } from "@apollo/client";
import React from "react";
import { studentClient } from "../../GraphqlApolloClients";
import Mentor from "./Mentor";

function Mentors({ student }) {
  const { data: { getMentorsByStudent: mentors } = {} } = useQuery(
    GET_MENTORS_BY_STUDENT,
    {
      variables: { studentId: student && student.id },
      client: studentClient,
    }
  );

  return mentors ? (
    <div className="flex flex-col w-full md:w-1/2">
      <h2 className="text-3xl mb-2 font-normal">Mentors</h2>
      {mentors.length > 0 ? (
        mentors.map((mentor, index) => <Mentor key={index} mentor={mentor} />)
      ) : (
        <p className="font-light text-left w-full text-lg">
          Once you're added by them, your mentor(s) will appear here.
        </p>
      )}
    </div>
  ) : (
    <></>
  );
}

export default Mentors;

export const GET_MENTORS_BY_STUDENT = gql`
  query getMentorsByStudent($studentId: String!) {
    getMentorsByStudent(studentId: $studentId) {
      id
      name
      email
      orgName
    }
  }
`;
